import ProductCard from "../product-card/product-card.component";
import { Link } from "react-router-dom";
import "./category-preview.styles.scss";

const CategoryPreview = ({ title, products }) => {
    return (
        <div className="category-preview-container">
            <h2>
                <span className="title">{title.toUpperCase()}</span>
            </h2>
            <div className="preview">
                {products
                    .filter((_, index) => index < 4)
                    .map((product) => (
                        <ProductCard key={product.id} product={product} />
                    ))}
            </div>
            <Link
                className="linkToFullProduct"
                to={`/shop/${title.toLowerCase()}`}>
                <span>SHOP ALL {title.toUpperCase()}</span>
            </Link>
        </div>
    );
};

export default CategoryPreview;
